import $ from 'jquery';
import { numberWithCommas } from "./custom_format_input_number.js";

const UNIT_KG = 'kg';

$(document).on('turbolinks:load', function () {
  initSelect2ProductsAjax();
  initSelect2ProductsAjax(false, $('.js-source-products-code-ajax'));
  initSelect2ProductsAjax(false, $('.js-source-products-gname-ajax'));
  handleSelectProductAjax();
  handleSelectShopOrder();
  handleFilterDateRaditoChangeState();
  fillProductSelectShopOnOrder();
  setTabIndex();
  resetShopDropdown();

  initSelect2OrderSourceShops();

  var current_pathname = location.pathname;
  if (current_pathname != "/orders/pick-up" || current_pathname != "/orders/pickup_orders"){
    $('.js-orders-source-shops').select2('open');
  }

  $('#order-items-nested-form .content-nested-form')
    .on('cocoon:after-insert', function (e, added_item) {
      added_item.after($('#js-link-to-add-item'));

      initSelect2ProductsAjax(false, added_item.find('.js-source-products-ajax'));
      // initSelect2ProductsAjax();
      handleSelectProductAjax();
      focusToFirstAmountField();
    });
});

$(document).on('shown.bs.modal', '#js-order-producing-modal', function() {
  handleSelectDirectiveTypeProduceOrder($('#js-order-producing-modal'));
});

$(document).on('shown.bs.modal', '#createNewProduceOrderModal', function() {
  $('.js-source-doughs').select2({
    dropdownParent: $('#createNewProduceOrderModal'),
    placeholder: "生地",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-doughs').on('select2:select', function (e) {
    var data = e.params.data;

    $.ajax({
      url: '/manufacturing/products/get_by_dough',
      method: 'GET',
      data: {
        dough_id: data.id,
        'authenticity_token': $('meta[name="csrf-token"]').attr('content')
      }
    }).done(function () {
      handleSelectDirectiveTypeProduceOrder($('#createNewProduceOrderModal'));
    });
  });

  $('.js-source-products-ajax').on('select2:select', function (e) {
    var data = e.params.data;
    let product_unit_type = data.unit_type;

    $('#createNewProduceOrderModal').find('.js-product-unit-type').html(product_unit_type);
  });
});

$(document).on('shown.bs.modal', '#addUnexpectedProductsModal', function() {
  initSelect2OrderSourceShops($('.modal.js-order-item-modal'));

  $('.js-orders-source-shops, .js-orders-pick-up-source-shops').on('select2:select', function (e) {
    var data = e.params.data;
    var shop_id = data.id;

    $('.js-add-order-items-btn').removeClass('d-none');

    process_fill_shop_products(shop_id);
  });

  let modalStateVal = '';
  modalStateVal = $('.js-order-item-modal').find('#js_state_add_items').val();
  if (modalStateVal == 'load_page') {
    let shopId = $('input.js-selected-shop-id').val();

    if (shopId == '' || shopId == undefined) {
      shopId = $('select.js-orders-source-shops').val();
      shopId = $('select.js-orders-puck-up-source-shops').val();
    }

    if (shopId != '' && shopId != undefined) {
      process_fill_shop_products(shopId);
    }
  }

  $('#order-items-nested-form')
    .on('cocoon:after-insert', function (e, added_item) {
      added_item.after($('#js-link-to-add-item'));

      initSelect2ProductsAjax($('.modal.js-order-item-modal'), added_item.find('.js-source-products-ajax'));
      handleSelectProductAjax();
    });
});

function handleSelectDirectiveTypeProduceOrder($dropdownParent) {
  $('.js-select-directive-type').select2({
    dropdownParent: $dropdownParent,
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-select-directive-type').on('select2:select', function(e) {
    let data = e.params.data;
    let elem = $(this).parent().parent().find('.js-unit-type-col');

    if (data.id == 'directive_product') {
      $(elem).html('個');
    } else if (data.id == 'directive_dough') {
      $(elem).html('本');
    } else if (data.id == 'directive_remain_dough') {
      $(elem).html('キロ');
    }
  });
}

function initSelect2OrderSourceShops(onModal = false) {
  let options = {
    ajax: {
      url: '/shops.json',
      dataType: 'json',
      data: function (params) {
        return {
          q: {name_or_code_cont: params.term},
          page: params.page || 1
        }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: $.map(data, function (item) {
            let item_name = item.name;
            let item_code = item.code;

            return {text: item_code + " " + item_name, id: item.id}
          })
        };
      },
      cache: true,
      minimumInputLength: 2
    },
    placeholder: "店舗コード - 店舗名",
    language: {
      noResults: function () {
        return "見つかりません";
      }
    },
    selectOnClose: false
  };

  if (onModal) {
    options.dropdownParent = onModal;
  }

  $('.js-orders-source-shops').select2(options);
  $('.js-orders-puck-up-source-shops').select2(options);
}

$(document).on('select2:open', () => {
  document.querySelector('.select2-search__field').focus();
});

$(document).on('click', '.js-btn-update-order-item-status', function (e) {
  e.preventDefault();

  let item_id = $(this).data('item-id');
  let update_status = '';
  if ($(this).hasClass('btn-order-item-status-not_accept_yet')) {
    update_status = 'planed_to_deliver';
  } else if ($(this).hasClass('btn-order-item-status-planed_to_deliver')) {
    update_status = 'not_accept_yet';
  }

  if (item_id != "" && update_status != "") {
    $.ajax({
      url: '/order_items/update_status',
      method: 'POST',
      data: {
        id: item_id,
        order_item: {status: update_status},
        'authenticity_token': $('meta[name="csrf-token"]').attr('content')
      }
    }).done(function () {
    });
  }
});

$(document).on('change', '.order-items-data .js-item-checkbox', function () {
  var productName = $(this).data("product-name");
  var isChecked = $(this).prop("checked");
  $.each($(".order-items-data .js-item-checkbox:checked"), function (i, el) {
    if (productName == $(el).data("product-name")) {
      $(el).prop("checked", false)
    }
  });

  $(this).prop("checked", isChecked);
});

$(document).on('click', '.js-add-order-items-btn', function (e) {
  e.preventDefault();

  let btn_state = $(this).data('btn-state');
  var shopId = '';

  if (btn_state == "onModal") {
    shopId = $('input.js-selected-shop-id').val();

    if (shopId == '' || shopId == undefined) {
      shopId = $('select.js-orders-source-shops').val();
    }
  } else {
    shopId = $('.js-confirm-fill-products-modal .js-current-select-shop-id').val();
  }

  $('.js-order-item-modal').modal('hide');
  getOrderItemsData(shopId);
});

$(document).on('click', '.js-check-duplicate-product-btn', function (e) {
  e.preventDefault();

  let items = $('.order-items-data input.js-item-checkbox:checked');

  var productIdsList = [];
  if (items.length > 0) {
    for (let i = 0; i < items.length; i++) {
      var productId = $(items[i]).data("product-id");
      productIdsList.push(productId);
    }
  }

  var isConfirm = false;

  $.each($(".js-select-product-col select"), function (i, dropdown) {
    if ($(dropdown).val() != "" && $.inArray(parseInt($(dropdown).val()), productIdsList) !== -1) {
      isConfirm = true;
    }
  });

  if (isConfirm) {
    $('#confirm-modal').modal('show');
  } else {
    $(".js-append-order-items-checked-btn").trigger("click");
  }
});

$(document).on('click', '.js-append-order-items-checked-btn, .js-confirm-order-item-modal-ok-btn', function (e) {
  e.preventDefault();

  let onModalDeliver = false;
  let btnOnModal = $(this).data('on-modal');
  let confirmOverWrite = false;

  if ($(this).hasClass('js-confirm-order-item-modal-ok-btn')) {
    confirmOverWrite = true;
  }

  if (btnOnModal != undefined && btnOnModal == 'add_products_deliver_modal') {
    onModalDeliver = true;
  }

  var items = $('.order-items-data input.js-item-checkbox:checked');

  if (onModalDeliver) {
    $('.js-add-order-items-data-modal').modal('hide');
    close_confirm_modal();
    $('.js-order-item-modal').modal('show');
    $('.js-order-item-modal').find('#js_state_add_items').val('add_history_items');
    processAppendShopProductsToModal(items, confirmOverWrite, true);
  } else {
    processAppendShopProductsToModal(items, confirmOverWrite);

    close_confirm_modal();
    close_modal_add_order_items();
  }
});

function processAppendShopProductsToModal(items, confirmOverWrite = false, onModalDeliver = false) {
  let existed_items = [];
  if (confirmOverWrite) {
    $('#order-items-nested-form .row-content').find('.row.item').remove();
  } else {
    existed_items = $('#order-items-nested-form .row-content').find('.row.item');
  }

  for (let i = 0; i < items.length; i++) {
    $('#order-items-nested-form .row-content a.js-add-one-item').click();
  }

  for (let i = existed_items.length; i < items.length + existed_items.length; i++) {
    let product_items = $('#order-items-nested-form .row-content').find('.row.item');
    let inserted_item = product_items[i];
    let item_data = items[i - existed_items.length];

    var productName = $(item_data).data("product-name");
    var productId = $(item_data).data("product-id");
    var amount = $(item_data).data("amount");
    var unitPrice = $(item_data).data("unit-price");
    var priceTotal = $(item_data).data("price");

    var newOption = new Option(productName, productId, true, true);
    $(inserted_item).find('.js-source-products-ajax').append(newOption).trigger('change');

    $(inserted_item).find('.js-product-price-col .js-product-price').val(unitPrice);
    $(inserted_item).find('input.js-order-item-amount').val(amount);
    $(inserted_item).find('input.js-order-total-fee').val(priceTotal);
  }
}

$(document).on('click', '.js-close-modal-add-order-items', function (e) {
  e.preventDefault();

  let onModalDeliver = false;
  let btnOnModal = $(this).data('on-modal');
  if (btnOnModal != undefined && btnOnModal == 'add_products_deliver_modal') {
    onModalDeliver = true;
  }

  if (onModalDeliver) {
    close_modal_add_order_items();
    $('.js-order-item-modal').modal('show');
  } else {
    close_modal_add_order_items();
  }
});

$(document).on('click', '.js-btn-import-order', function (e) {
  e.preventDefault();

  $(".js-input-file-field").trigger("click");
});

$(document).on('click', '.js-confirm-cancel-btn', function (e) {
  e.preventDefault();

  close_confirm_modal();
});

function getOrderItemsData(shopId) {
  if (shopId != "") {
    $.ajax({
      url: '/orders/get_orders_by_shop',
      method: 'GET',
      data: {id: shopId, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function (response) {
      $(".js-add-order-items-data-modal .order-items-data").html("");
      var str = "";
      if (response.data.length > 0) {
        $.each(response.data, function (i, order) {
          if (order.items.length != 0) {
            str += `<div class="mt-3 date-strf">${order.date_strf}</div>
          <div class="card mt-1">
            <div class="card-body">
              <div class="workers-list-wrapper modal-order-items-list-items">
                <div class="workers-list">
                  <div class="row row-head">
                    <div class="col-1"></div>
                    <div class="col-4">商品名</div>
                    <div class="col-2">個数</div>
                    <div class="col-2">単価</div>
                    <div class="col-3">金額</div>
                  </div>
                  <div class="row-content">`;

            $.each(order.items, function (j, item) {
              str += `<div class="row row-item">
                      <div class="col-1 d-margin-auto text-center">
                        <input type="checkbox" class="js-item-checkbox" data-product-name="${item.product_name}" data-product-id="${item.product_id}"
                        data-amount="${item.amount}" data-unit-price="${item.unit_price}" data-price="${item.price}"
                        data-product-code="${item.product_code}" data-product-general-name="${item.product_general_name}" />
                      </div>
                      <div class="col-4 d-margin-auto">
                         ${item.product_name}
                      </div>
                      <div class="col-2 d-margin-auto">
                        ${numberWithCommas(item.amount)}
                      </div>
                      <div class="col-2 d-margin-auto">
                        ¥${numberWithCommas(item.unit_price)}
                      </div>
                      <div class="col-3 d-margin-auto">
                        ¥${numberWithCommas(item.price)}
                      </div>
                    </div>`;
            });

            str += `</div>
                </div>
              </div>
            </div>
          </div>
        </div>`;
          }
        });
      } else {
        str += `<div class="card mt-1">
            <div class="card-body">
              <div class="workers-list-wrapper modal-order-items-list-items">
                <div class="workers-list">
                  <div class="row row-head">
                    <div class="col-1"></div>
                    <div class="col-4">商品名</div>
                    <div class="col-2">個数</div>
                    <div class="col-2">単価</div>
                    <div class="col-3">金額</div>
                  </div>
                  <div class="row-content">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`;
      }

      $(".js-add-order-items-data-modal .order-items-data").append(str);
      $('.js-add-order-items-data-modal').modal('show');
    });
  }
}

function close_modal_add_order_items() {
  initSelect2ProductsAjax();
  $('.js-add-order-items-data-modal').modal('hide');
}

function close_confirm_modal() {
  initSelect2ProductsAjax();
  $('#confirm-modal').modal('hide');
}

function fillProductSelectShopOnOrder() {
  $('.js-orders-source-shops').on('select2:select', function (e) {
    var data = e.params.data;
    let current_selected_shop_id = $('input.js-selected-shop-id').val();
    var shopDropdownCount = localStorage.getItem("shopDropdownCount");

    $('.js-confirm-fill-products-modal .js-current-select-shop-id').val(data.id);
    // $(".js-add-order-items-btn").focus();

    if (parseInt(shopDropdownCount) > 0) {
      $('.js-confirm-fill-products-modal').modal('show');
    } else {
      process_fill_shop_products(data.id);
      localStorage.setItem("shopDropdownCount", shopDropdownCount + 1);
      focusToFirstAmountField();
    }
  });
}

$(document).on('click', '.js-confirm-fill-products-modal .js-cancel-btn', function (e) {
  e.preventDefault();

  let current_selected_shop_id = $('input.js-selected-shop-id').val();
  $('.js-orders-source-shops').val(current_selected_shop_id).trigger('change');
  $('.js-confirm-fill-products-modal').modal('hide');
  $('.js-orders-source-shops').select2('focus');
});

$(document).on('click', '.js-confirm-fill-products-modal .js-confirm-ok-btn', function (e) {
  e.preventDefault();

  let current_selected_shop_id = $('input.js-current-select-shop-id').val();
  $('input.js-selected-shop-id').val(current_selected_shop_id);
  $('.js-orders-source-shops').val(current_selected_shop_id).trigger('change');
  $('.js-confirm-fill-products-modal').modal('hide');
  $('.js-orders-source-shops').select2('focus');

  process_fill_shop_products(current_selected_shop_id);
  setTabIndex();
});

$(document).on('change', '.js-input-file-field', function(e){
  e.preventDefault();

  $(".js-form-import-csv-btn").trigger("click");
});

function process_fill_shop_products(shop_id) {
  $.ajax({
    url: '/shop_products/list_order_shop_products',
    method: 'POST',
    data: {shop_id: shop_id, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function (rsp) {
    $('#order-items-nested-form .row-content').find('.row.item').remove();

    if (rsp.length > 0) {
      for (let i = 0; i < rsp.length; i++) {
        $('#order-items-nested-form .row-content a.js-add-one-item').click();
      }

      for (let i = 0; i < rsp.length; i++) {
        let product_items = $('#order-items-nested-form .row-content').find('.row.item');
        let inserted_item = product_items[i];

        var newOption = new Option(rsp[i].text, rsp[i].id, true, true);
        $(inserted_item).find('.js-source-products-ajax').append(newOption).trigger('change');
        $(inserted_item).find('.js-product-price-col .js-product-price').val(numberWithCommas(rsp[i].display_price));
        $(inserted_item).find('label.js-product-unit-type').html(rsp[i].unit_type);

        if (rsp[i].unit_type == UNIT_KG) {
          $(inserted_item).find('input.js-order-item-amount').removeClass('int-field').addClass('float-field');
        }
      }
    } else {
      $('#order-items-nested-form .row-content a.js-add-five-items').click();
    }
  });
}

function handleFilterDateRaditoChangeState() {
  let item_dom = 'input[type="radio"][name="order[delivery_options]"]';

  $(item_dom).change(function () {
    let parent = $(this).parents('.js-filter-delivery-date-options');
    let input_dom1 = 'input[name="order[delivery_date1]"';
    let input_dom2 = 'input[name="order[delivery_date2]"';
    let input_date1 = $(parent).find(input_dom1);
    let input_date2 = $(parent).find(input_dom2);

    if (this.value == 'on_time') {
      $(input_date1).val('');
      $(input_date2).val('');
      $(input_date1).prop('disabled', true);
      $(input_date2).prop('disabled', true);
    } else if (this.value == 'range_time') {
      $(input_date1).prop('disabled', false);
      $(input_date2).prop('disabled', false);
    } else if (this.value == 'all_time') {
      $(input_date1).val('');
      $(input_date2).val('');
    }
  });
}

function initSelect2ProductsAjax(onModal = false, $specific_item = null) {
  let $dom_elem = $('.js-source-products-ajax');

  if ($specific_item != null) {
    $dom_elem = $specific_item;
  }

  let options = {
    ajax: {
      url: '/products.json',
      dataType: 'json',
      data: function (params) {
        let query = {name_or_code_or_general_name_or_client_products_special_product_name_cont: params.term};

        let except_product_ids = [];
        let items = $('.js-select-product-col select.js-source-products-ajax');
        for (let i = 0; i < items.length; i++) {
          let p_id = $(items[i]).val();
          if (p_id != null && p_id != "") {
            except_product_ids.push(p_id);
          }
        }

        return {
          q: query,
          shop_id_selected: $('select.js-orders-source-shops').val(),
          except_product_ids: except_product_ids,
          page: params.page || 1
        }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: $.map(data, function (item) {
            let item_name = item.name;
            let item_price = item.price;
            let item_unit_type = item.unit_type_localization;

            if (item.client_id_selected != null) {
              for (let i = 0; i < item.client_products.length; i++) {
                if (item.client_id_selected == item.client_products[i].client_id) {
                  item_name = item.client_products[i].special_product_name;
                  item_price = item.client_products[i].price;

                  break;
                }
              }
            }

            let item_text_display = item_name + '、' + item.code;
            if (item.general_name != null && item.general_name != '') {
              item_text_display += '、' + item.general_name;
            }

            return {text: item_text_display, id: item.id, price: item_price, unit_type: item_unit_type}
          })
        };
      },
      cache: true,
      minimumInputLength: 1
    },
    language: {
      noResults: function () {
        return "見つかりません";
      }
    },
    selectOnClose: false
  };

  if (onModal) {
    options.dropdownParent = onModal;
  }

  $dom_elem.select2(options);
}

function handleSelectShopOrder() {
  $('.js-orders-source-shops').on('select2:select', function (e) {
    var data = e.params.data;
    var shop_id = data.id;

    $(this).closest(".orders-form-block").find(".js-add-order-items-btn").removeClass("d-none");
  });
}

function correctNumberFieldByFormatted(input_value, item_type) {
  if (input_value == undefined || input_value == "") {
    return "";
  }

  if (item_type == 'int') {
    return parseInt(input_value.replace(',', ''));
  } else {
    return parseFloat(input_value.replace(',', ''));
  }
}

function handleSelectProductAjax() {
  $('.js-source-products-ajax').on('select2:select', function (e) {
    var data = e.params.data;

    var current_row = $(e.currentTarget).parents('.row.item');
    var item_amount = $(current_row).find('.js-order-item-amount-col input.js-order-item-amount').val().trim();
    var item_price = numberWithCommas(data.price);

    $(current_row).find('.js-order-item-amount-col label.js-product-unit-type').html(data.unit_type);
    $(current_row).find('.js-product-price-col input.js-product-price').val(item_price);
    $(current_row).find('.js-source-products-ajax').val(data.id).trigger('change');
    $(current_row).find('.js-select-product-col').find('.select2-selection__rendered').text(data.text);

    if (item_amount != "") {
      item_amount = item_amount.replace(',', '');
      var order_item_total_fee = data.price * parseFloat(item_amount);
      order_item_total_fee = parseInt(order_item_total_fee);

      var display_order_item_total_fee = numberWithCommas(order_item_total_fee);
      $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val(display_order_item_total_fee);
    }

    if (data.unit_type == UNIT_KG) {
      $(current_row).find('input.js-order-item-amount').removeClass('int-field').addClass('float-field');
    } else {
      $(current_row).find('input.js-order-item-amount').removeClass('float-field').addClass('int-field');
    }
  });

  $('input.js-order-item-amount').keyup(function (event) {
    var current_row = $(this).parents('.row.item');
    var product_price = $(current_row).find('.js-product-price-col input.js-product-price').val();
    var current_input = $(this).val().trim();

    product_price = correctNumberFieldByFormatted(product_price);
    current_input = correctNumberFieldByFormatted(current_input);

    if (current_input == undefined || current_input == '') {
      $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val('');
    }

    if ($.isNumeric(current_input) && $.isNumeric(product_price)) {
      let item_amount_converted = parseFloat(current_input);
      let product_price_converted = parseFloat(product_price);
      var order_item_total_fee = item_amount_converted * product_price_converted;
      order_item_total_fee = parseInt(order_item_total_fee);

      var display_order_item_total_fee = numberWithCommas(order_item_total_fee);
      $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val(display_order_item_total_fee);
    }
  });

  $('input.js-product-price').keyup(function (event) {
    var current_row = $(this).parents('.row.item');
    let order_item_amount = $(current_row).find('.js-order-item-amount-col input.js-order-item-amount').val();
    let current_input = $(this).val().trim();

    order_item_amount = correctNumberFieldByFormatted(order_item_amount);
    current_input = correctNumberFieldByFormatted(current_input);

    if ($.isNumeric(current_input) && $.isNumeric(order_item_amount)) {
      let item_amount_converted = parseFloat(current_input);
      let order_item_amount_converted = parseFloat(order_item_amount);
      var order_item_total_fee = item_amount_converted * order_item_amount_converted;
      order_item_total_fee = parseInt(order_item_total_fee);

      var display_order_item_total_fee = numberWithCommas(order_item_total_fee);
      $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val(display_order_item_total_fee);
    }

    if (current_input == null || current_input == "") {
      $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val("");
    }
  });
}

function setTabIndex() {
  localStorage.setItem("tabIndex", 0);
}

function resetShopDropdown() {
  localStorage.setItem("shopDropdownCount", 0);
}

// Press Tab and Shift Tab to next and previous amount element
document.onkeydown = function (e) {
  var el = $("#order-items-nested-form .js-order-item-amount");
  var totalEl = el.length;
  var tabIndex = localStorage.getItem("tabIndex");
  var currentIndex = parseInt(tabIndex);
  var nextIndex = currentIndex;

  if (e.keyCode === 9) {
    e.preventDefault();

    $(".js-orders-source-shops").select2("close");

    if (e.shiftKey) {
      if (currentIndex == 0) {
        $(el)[nextIndex].focus();
        nextIndex = 0;
      } else if (currentIndex >= totalEl - 1) {
        $(el)[totalEl - 1].focus();
        nextIndex = totalEl - 1;
        if(totalEl > 1) { nextIndex -= 1;}
      } else {
        $(el)[nextIndex].focus();
        nextIndex -= 1;
      }
    } else {
      if (currentIndex >= totalEl - 1) {
        $("#new_order .btns-control .btn-save").focus();
        nextIndex = currentIndex;
      } else {
        nextIndex += 1;
        $(el)[nextIndex].focus();
      }
    }

    localStorage.setItem("tabIndex", nextIndex);
  }

  if (e.ctrlKey) {
    // e.preventDefault(); If uncomment this line, we can not copy & paste using ctrl + c, v

    if (e.keyCode == 79) {
      window.location.href = '/orders/new';
    }
  }
};

function focusToFirstAmountField(){
  var el = $("#order-items-nested-form .js-order-item-amount");
  var totalEl = el.length;
  if (totalEl > 0) {
    $($(el)[0]).focus();
  }
}
