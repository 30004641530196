import $ from "jquery";

$(document).on('turbolinks:load', function () {
  if (!$("#deliver_order_departure_at").data("isupdated")) {
    $("#deliver_order_departure_at").val('');
  }
});

$(document).on('click', '.js-update-deliver-field .timing-icon', function (e) {
  $(this).closest(".time_data").find("input.timepicker").trigger("click");
});

$(document).on("click", ".js-return-btn-dismiss", function(){
  $(this).closest(".js-return-registration-modal").find(".content-nested-form .remove_fields").trigger("click");
});

$(document).on("click", ".js-choose-pdf-type-btn", function(e) {
  e.preventDefault();

  $('#js-choose-pdf-type-modal').modal('show');
});

$(document).on('click', '.js-btn-export-batch-order_item-print', function (e) {
  e.preventDefault();

  var order_delivery_date1 = $('input#order_delivery_date1').val();
  var order_delivery_date2 = $('input#order_delivery_date2').val();

  if (order_delivery_date1 == '' || order_delivery_date2 == '') {
    $('.js-not-choose-range-date-alert-modal').modal('show');
  } else {
    let query_string = location.href;
    let print_type = 'slip_document';
    let url = '/delivers/batch_generate_all_pdfs_modal';

    $.ajax({
      url: url,
      method: 'GET',
      data: {
        'authenticity_token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/pdf',
        'print_type': print_type,
        'query_string': query_string
      }
    }).done(function (data) {
      let shops_pdfs = data['deliver_data']['slip_document']['shop_ids'];
      $('.js-num-shops-pdf').html(shops_pdfs.length);

      if (shops_pdfs.length == 0) {
        $('.js-batch-generate-pdf-btn').prop('disabled', true);
      } else {
        $('.js-batch-generate-pdf-btn').prop('disabled', false);
      }
    });

    $('#js-batch-choose-pdf-type-modal').modal('show');
  }
});

$(document).on("click", ".js-batch-generate-pdf-btn", function(e) {
  e.preventDefault();

  let $this = $(this);
  let print_type = $(this).data('print-type');
  let query_string = location.href;
  let url = '/delivers/batch_generate_all_pdfs';

  $this.prop('disabled', true);

  $.ajax({
    url: url,
    method: 'GET',
    data: {
      'authenticity_token': $('meta[name="csrf-token"]').attr('content'),
      'Content-Type': 'application/pdf',
      'print_type': print_type,
      'query_string': query_string
    }
  }).done(function (data) {
    $this.prop('disabled', false);

    if (data['file_path']) {
      printJS({printable: data['file_path'].replace('public/', ''), type: 'pdf', showModal: true});
    } else {
      alert("全ての店舗に伝票タイプを入力してください。");
    }
  });
});

$(document).on("click", ".js-generate-pdf-btn", function(e) {
  e.preventDefault();

  let deliver_id = $(this).data('item-id');
  let item_type = $(this).data('item-type');
  let print_order_item_ids = $('#print_order_item_ids').val();
  let data_type_of_sheets = localStorage.getItem("data_type_of_sheets");
  let url = '/delivers/generate_all_pdfs';
  let $this = $(this);

  $this.prop('disabled', true);

  $.ajax({
    url: url,
    method: 'GET',
    data: {
      'authenticity_token': $('meta[name="csrf-token"]').attr('content'),
      'Content-Type': 'application/pdf',
      'id': deliver_id,
      'item_type': item_type,
      'print_order_item_ids': print_order_item_ids,
      'data_type_of_sheets': data_type_of_sheets
    }
  }).done(function (data) {
    $this.prop('disabled', false);

    if (data['file_path']) {
      printJS({printable: data['file_path'], type: 'pdf', showModal: true});
    } else {
      alert("全ての店舗に伝票タイプを入力してください。");
    }
  });
});

$(document).on('click', '.js-btn-return-all-orders-not-delivered', function (e) {
  e.preventDefault();

  $('.js-return-all-orders-not-delivered-modal').modal('show');
});

$(document).on('click', '.js-confirm-return-all-orders-not-delivered-modal', function (e) {
  e.preventDefault();

  let deliver_id = $(this).data('deliver-id');

  $.ajax({
    url: '/delivers/' + deliver_id + '/return_all_orders_not_delivered',
    method: 'POST',
    data: {
      'authenticity_token': $('meta[name="csrf-token"]').attr('content')
    }
  }).done(function () {
  });
});
