import $ from 'jquery';

$(document).on('turbolinks:load', function () {
  initSelect2SourceShops();
});

export function initSelect2SourceShops() {
  let options = {
    ajax: {
      url: '/shops.json',
      dataType: 'json',
      data: function (params) {
        return {
          q: {name_or_code_cont: params.term},
          page: params.page || 1
        }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: $.map(data, function (item) {
            let item_name = item.name;
            let item_code = item.code;

            return {text: item_code + " " + item_name, id: item.id}
          })
        };
      },
      cache: true,
      minimumInputLength: 2
    },
    placeholder: "店舗コード - 店舗名",
    language: {
      noResults: function () {
        return "見つかりません";
      }
    },
    selectOnClose: false
  };

  $('.route-page .js-source-shops').select2(options);
}
