import $ from 'jquery'
import { initSelect2SourceShops } from "./route_points.js";

$(document).on('turbolinks:load', function () {
  var directionsService = new google.maps.DirectionsService();
  var directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});

  var map = initMap(directionsService, directionsRenderer);
  handleSelectShop(map, directionsService, directionsRenderer);
  updateSelectShopOptions();

  // Render map after page load
  if ($('.deliver-map').length > 0) {
    var items = $('.deliver-map').find('.row.item');
    if (items.length > 0) {
      drawAllRoute(map, directionsService, directionsRenderer);
    }
  }

  $(".route-sortable").sortable({
    items: ".row.item:not(.unsortable)",
    update: function (event, ui) {
      let sortable_wrapper = ui.item.parents('.route-sortable');
      let items = sortable_wrapper.find('.row.item:not([style*="display: none"])');

      for (let i = 0; i < items.length; i++) {
        let item_index = i + 1;

        $(items[i]).find('.js-sort-index-col .display-index-value').html(item_index);
        $(items[i]).find('.js-sort-index-col input.js-sort-index-val').val(item_index);
      }

      drawAllRoute(map, directionsService, directionsRenderer);
    }
  });

  $(".sortable").sortable({
    items: "tr.item:not(.unsortable)",
    update: function (event, ui) {
      let sortable_wrapper = ui.item.parents('.sortable');
      let items = sortable_wrapper.find('tr.item:not([style*="display: none"])');

      for (let i = 0; i < items.length; i++) {
        let item_index = i + 1;

        $(items[i]).find('input.js-sort-index-val').val(item_index);
      }

      $(this).closest('form').submit();
    }
  });

  $(document).on('select2:select', '.js-select2-shops', function () {
    updateSelectShopOptions();
  })

  $('#client_products')
    .on('cocoon:after-insert', function (e, added_item) {
      $('#js-link-to-add-item').prev().after(added_item);

      $('.js-source-clients').select2({
        placeholder: '得意先名',
        language: {
          noResults: function () {
            return '見つかりません';
          }
        }
      });
    }).on('cocoon:before-remove', function (event) {
    var confirmation = confirm('本当に削除しますか?');
    if (confirmation === false) {
      event.preventDefault();
    }
  });

  $('#dough_materials')
    .on('cocoon:after-insert', function (e, added_item) {
      $('#js-link-to-add-item').prev().after(added_item);

      $('.js-source-materials').select2({
        placeholder: '原材料',
        language: {
          noResults: function () {
            return '見つかりません';
          }
        }
      });
    }).on('cocoon:before-remove', function (event) {
    var confirmation = confirm('本当に削除しますか?');
    if (confirmation === false) {
      event.preventDefault();
    }
  });

  $('#route-points-nested-form .content-nested-form')
    .on('cocoon:after-insert', function (e, added_item) {
      added_item.after($('#js-link-to-add-item'));

      let sortable_wrapper = $(added_item).parents('.route-sortable');
      let items = sortable_wrapper.find('.row.item:not([style*="display: none"])');

      let added_item_index = items.length;

      $(added_item).find('.js-sort-index-col .display-index-value').html(added_item_index);
      $(added_item).find('.js-sort-index-col input.js-sort-index-val').val(added_item_index);

      initSelect2SourceShops();
      updateSelectShopOptions();
      handleSelectShop(map, directionsService, directionsRenderer);
    })
    .on("cocoon:after-remove", function (e, removed_item) {
      updateSelectShopOptions();
      let sortable_wrapper = $(e.currentTarget);
      let items = sortable_wrapper.find('.row.item:not([style*="display: none"])');

      for (let i = 0; i < items.length; i++) {
        let item_index = i + 1;

        $(items[i]).find('.js-sort-index-col .display-index-value').html(item_index);
        $(items[i]).find('.js-sort-index-col input.js-sort-index-val').val(item_index);
      }
      drawAllRoute(map, directionsService, directionsRenderer);
    });

  $('input.js-client-products-search-by-client').keyup(function (event) {
    let current_input = $(this).val().trim();
    let rsp_items = $('#client_products').find('.client-product-item');

    for (let i = 0; i < rsp_items.length; i++) {
      let current_item_client_name = $(rsp_items[i]).data('client-name');

      if (current_item_client_name != '') {
        if (current_item_client_name.indexOf(current_input) != -1) {
          $(rsp_items[i]).removeClass('d-none');
        } else {
          $(rsp_items[i]).addClass('d-none');
        }
      }
    }
  }).keydown(function (event) {
    if (event.which == 13) {
      event.preventDefault();
    }
  });

  $('input.js-dough-materials-search-by-name').keyup(function (event) {
    let current_input = $(this).val().trim();
    let rsp_items = $('#dough_materials').find('.dough-material-item');

    for (let i = 0; i < rsp_items.length; i++) {
      let current_item_client_name = $(rsp_items[i]).data('material-name');

      if (current_item_client_name != '') {
        if (current_item_client_name.indexOf(current_input) != -1) {
          $(rsp_items[i]).removeClass('d-none');
        } else {
          $(rsp_items[i]).addClass('d-none');
        }
      }
    }
  }).keydown(function (event) {
    if (event.which == 13) {
      event.preventDefault();
    }
  });

  $('input.js-sub-selector-search-by-name').keyup(function (event) {
    let current_input = $(this).val().trim();
    let rsp_items = $('#sub-selector').find('.sub-item');

    for (let i = 0; i < rsp_items.length; i++) {
      let current_item_client_name = '';
      if ($(rsp_items[i]).data('item')) {
        current_item_client_name = $(rsp_items[i]).data('item').toString().toLowerCase();
      }

      if (current_item_client_name.indexOf(current_input.toString().toLowerCase()) != -1) {
        $(rsp_items[i]).removeClass('d-none');
      } else {
        $(rsp_items[i]).addClass('d-none');
      }
    }

    var is_null = [];
    for (let i = 0; i < rsp_items.length; i++) {
      if ($(rsp_items[i]).hasClass("d-none")){
        is_null.push(true)
      } else {
        is_null.push(false)
      }
    }

    var resp = Array.from(new Set(is_null));

    if (resp.length == 1 && resp[0] == true) {
      $(".js-sub-selector-modal").find(".search-error-block").removeClass("d-none");
      $(".js-sub-selector-modal").find(".search-error-msg").removeClass("d-none");
    } else {
      $(".js-sub-selector-modal").find(".search-error-block").addClass("d-none");
      $(".js-sub-selector-modal").find(".search-error-msg").addClass("d-none");
    }
  }).keydown(function (event) {
    if (event.which == 13) {
      event.preventDefault();
    }
  });

  $('input.js-sub-pie-selector-search-by-name').keyup(function (event) {
    let current_input = $(this).val().trim();
    let rsp_items = $('#sub-pie-selector').find('.sub-item');

    for (let i = 0; i < rsp_items.length; i++) {
      let current_item_client_name = '';
      if ($(rsp_items[i]).data('item')) {
        current_item_client_name = $(rsp_items[i]).data('item').toString().toLowerCase();
      }

      if (current_item_client_name.indexOf(current_input.toString().toLowerCase()) != -1) {
        $(rsp_items[i]).removeClass('d-none');
      } else {
        $(rsp_items[i]).addClass('d-none');
      }
    }

    var is_null = [];
    for (let i = 0; i < rsp_items.length; i++) {
      if ($(rsp_items[i]).hasClass("d-none")){
        is_null.push(true)
      } else {
        is_null.push(false)
      }
    }
    var resp = Array.from(new Set(is_null));

    if (resp.length == 1 && resp[0] == true) {
      $(".js-sub-pie-selector-modal").find(".search-error-block").removeClass("d-none");
      $(".js-sub-pie-selector-modal").find(".search-error-msg").removeClass("d-none");
    } else {
      $(".js-sub-pie-selector-modal").find(".search-error-block").addClass("d-none");
      $(".js-sub-pie-selector-modal").find(".search-error-msg").addClass("d-none");
    }
  }).keydown(function (event) {
    if (event.which == 13) {
      event.preventDefault();
    }
  });
});

$(function () {
  $(document).on('input', '.int-field', function (e) {
    if (/\D/g.test(this.value)) {
      this.value = this.value.replace(/\D/g, '');
    }
    if ($(this).val().length > 10) {
      $(this).val($(this).val().slice(0, -1))
    }
  });

  $(document).on('input', '.float-field', function (evt) {
    var self = $(this);

    // self.val(self.val().replace(/[^0-9\.]/g, ''));
    self.val(self.val().replace(/(?!^-)[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
    if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) {
      evt.preventDefault();
    }

    var number_after_dot = self.val().toString().split('.')[1];
    if (number_after_dot && number_after_dot.toString().length > 1) {
      var current_value = self.val().toString().slice(0, -1);
      self.val(parseFloat(current_value));
    }
  });
});

function updateSelectShopOptions() {
  $('.js-source-shops').each(function () {
    var selectedIds = [];
    $('.js-source-shops:visible').not(this).each(function () {
      selectedIds.push(this.value);
    });
    $(this.options).each(function () {
      if (selectedIds.includes(this.value)) {
        $(this).attr('disabled', true);
      } else {
        $(this).removeAttr('disabled');
      }
    });
  });
}


function initMap(directionsService, directionsRenderer) {
  let all_items = $('#route-points-nested-form .content-nested-form.route-sortable').find('.row.item:not([style*="display: none"])');
  let items = [];

  let start_coordinate = {lat: 35.18158812238109, lng: 136.90656649808557};
  let inValidCoord = [null, ""];

  for (let i = 0; i < all_items.length; i++) {
    let item_cor = JSON.parse($(all_items[i]).find('.js-item-coordinate').val());
    if (item_cor.lat != null && item_cor.lng != null) {
      items.push(all_items[i]);
    }
  }

  if (items.length > 0 && inValidCoord.indexOf($(items[0]).find('.js-item-coordinate').val()) == -1) {
    start_coordinate = JSON.parse($(items[0]).find('.js-item-coordinate').val());
  }

  if ($('.deliver-map').length > 0) {
    items = $('.deliver-map').find('.row.item');
    if (items.length > 0 && inValidCoord.indexOf($(items[0]).find('.js-item-coordinate').html()) == -1) {
      start_coordinate = JSON.parse($(items[0]).find('.js-item-coordinate').html());
    }
  }

  var start_pos = new google.maps.LatLng(start_coordinate.lat, start_coordinate.lng);

  if ($('#route-map').length > 0) {
    var map = new google.maps.Map(document.getElementById('route-map'), {
      center: start_pos,
      markers: [],
      zoom: 8,
      disableDefaultUI: true,
      gestureHandling: 'greedy',
      styles: [
        {
          "featureType": "poi",
          "stylers": [
            {"visibility": "off"}
          ]
        }
      ]
    });

    directionsRenderer.setMap(map);

    if (items.length > 0) {
      drawAllRoute(map, directionsService, directionsRenderer);
    }
  } else {
    var map = "";
  }

  return map;
}

function handleSelectShop(map, directionsService, directionsRenderer) {
  $('.js-select2-shops').on('select2:select', function (e) {
    var data = e.params.data;
    var shop_id = data.id;
    var current_row = $(e.currentTarget).parents('.row.item');

    $.ajax({
      url: '/shops/' + shop_id,
      method: 'GET',
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function (data) {
      $(current_row).find('.js-shop-address-col').html(data.address);
      $(current_row).find('.js-item-coordinate').val(data.coordinate_json);
      drawAllRoute(map, directionsService, directionsRenderer);
    });
  });
}

function clearOverlays(markersArray) {
  while (markersArray.length) {
    markersArray.pop().setMap(null);
  }
}

function drawAllRoute(map, directionsService, directionsRenderer, stopover = true) {
  let all_items = $('#route-points-nested-form .content-nested-form.route-sortable').find('.row.item:not([style*="display: none"])');
  if ($('.deliver-map').length > 0) {
    all_items = $('.deliver-map').find('.row.item');
  }

  let items = [];

  for (let i = 0; i < all_items.length; i++) {
    let this_item_address = $(all_items[i]).find('.js-shop-address-col').html().trim();

    if (this_item_address != undefined && this_item_address != null && this_item_address != "") {
      let item_cor = "";
      if ($('.deliver-map').length > 0) {
        item_cor = JSON.parse($(all_items[i]).find('.js-item-coordinate').html().trim());
      } else {
        item_cor = JSON.parse($(all_items[i]).find('.js-item-coordinate').val());
      }
      if (item_cor.lat != null && item_cor.lng != null) {
        items.push(all_items[i]);
      }
    }
  }

  let waypts = [];
  // let actual_items_index = [];
  let is_exist_all_address = true;
  if (items.length == 0) return clearOverlays(map.markers);

  // let start_pos = $(items[0]).find('.js-shop-address-col').html().trim();
  // let end_pos = $(items[items.length - 1]).find('.js-shop-address-col').html().trim();
  // let start_index = $(items[0]).find('.js-sort-index-col input.js-sort-index-val').val().trim();
  // let end_index = $(items[items.length - 1]).find('.js-sort-index-col input.js-sort-index-val').val().trim();
  //
  // actual_items_index.push(start_index);

  var parts = [];
  for (var i = 0, max = 24; i < items.length; i = i + max) {
    parts.push(items.slice(i, i + max + 1));
  }

  clearOverlays(map.markers);

  var data = [];

  for (let i = 0; i < parts.length; i++) {
    var actual_items_index = [];
    var waypoints = [];

    let start_index = $(parts[i][0]).find('.js-sort-index-col input.js-sort-index-val').val().trim();
    let end_index = $(parts[i][parts[i].length - 1]).find('.js-sort-index-col input.js-sort-index-val').val().trim();

    actual_items_index.push(start_index);

    for (let j = 1; j < parts[i].length - 1; j++) {
      let item_address = $(parts[i][j]).find('.js-shop-address-col').html().trim();
      if (item_address == "") {
        is_exist_all_address = false;
        return false;
      }

      waypoints.push({
        location: item_address,
        stopover: stopover
      });
      let waypt_item_index = $(parts[i][j]).find('.js-sort-index-col input.js-sort-index-val').val().trim();
      actual_items_index.push(waypt_item_index);
    }

    actual_items_index.push(end_index);

    var current_start_pos = $(parts[i][0]).find('.js-shop-address-col').html().trim();
    var current_end_pos = $(parts[i][parts[i].length - 1]).find('.js-shop-address-col').html().trim();
    var service_options = {
      origin: current_start_pos,
      destination: current_end_pos,
      waypoints: waypoints,
      // optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
      avoidTolls: true,
    };

    data.push({service_options: service_options, actual_items_index: actual_items_index});
  }

  function calculateRoute(service_options, actual_items_index) {
    directionsService.route(service_options, function(result, status) {
      if (status == "OK") {
        var directionsDisplay = new google.maps.DirectionsRenderer({
          map: map,
          suppressMarkers: true
        });
        directionsDisplay.setDirections(result);

        const route = result.routes[0];
        let end_index = actual_items_index[actual_items_index.length - 1];

        for (let i = 0; i < route.legs.length; i++) {
          let textLabel = "";
          if (stopover) {
            textLabel = "" + actual_items_index[i];
          } else {
            textLabel = start_index;
          }

          var marker = new google.maps.Marker({
            position: route.legs[i].start_location,
            label: {
              text: textLabel,
              color: '#ffffff'
            },
            map: map
          });

          map.markers.push(marker);
        }

        var marker = new google.maps.Marker({
          position: route.legs[route.legs.length - 1].end_location,
          label: {
            text: end_index,
            color: '#ffffff'
          },
          map: map
        });

        map.markers.push(marker);
      }
    });
  }

  for (let i = 0; i < data.length; i++) {
    var service_options = data[i]['service_options'];
    var actual_items_index = data[i]['actual_items_index'];

    calculateRoute(service_options, actual_items_index);
  };
}
